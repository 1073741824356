// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oD898 {}\n\n.oD898._1e3rz {\n    visibility: hidden;\n}\n\n.oD898 * {\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n}\n\n.oD898 .B6Dc2{\n    font-size: 14px;\n    font-size: 0.875rem;\n    line-height: 20px;\n    color: var(--font-color);\n    letter-spacing: normal;\n}\n\n.oD898 h1, .oD898 h2, .oD898 h3, .oD898 h4, .oD898 h5, .oD898 h6, .oD898 strong {\n    font-weight: 500;\n    color: var(--font-color);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "oD898",
	"hidden": "_1e3rz",
	"dialogContent": "B6Dc2"
};
module.exports = ___CSS_LOADER_EXPORT___;
