// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sOWMh{}\n\n.sOWMh > div {\n    margin-bottom: 16px;\n}\n\n.sOWMh > div:last-child {\n    margin-bottom: 0;\n}\n\n.aX1VZ{\n    font-size: 14px;\n    font-size: 0.875rem;\n    line-height: 20px;\n    color: var(--font-color);\n    letter-spacing: normal;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "sOWMh",
	"content": "aX1VZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
